<template>
  <div id="performance-metric">
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <vx-card class="big-title-card-bg-black h-auto">
          <h1 class="rg-big-title extrabold rg-title">
              Reporte <span>operativo</span>.
          </h1>
        </vx-card>
      </div>
      <div class="vx-col w-full mb-6">
        <vx-card title="Da seguimiento al programa.">
          <p>El propósito de este reporte es identificar el nivel de uso/cambio de los estados de Prospecto y Proyecto para todo el Programa o a nivel Zona o Región.
          <br>Lo anterior permite identificar qué tan activos están los Proveedores participando en el Programa. Por ejemplo, si un Proveedor actualizó el estado de dos prospectos de "Interesados en recibir propuesta" a "Con propuesta realizada", se observará un "2" para el día en que haya realizado ese cambio de estado.</p>
        </vx-card>
      </div>
    </div>
    <div class="filter-container mb-6">
      <vx-card title="Filtros.">
        <div class="flex">
          <vs-select label="Visualización" v-model="viewFormSelected" class="mr-2" @change="changeVisualization">
            <vs-select-item text="Diario" value="daily"></vs-select-item>
            <vs-select-item text="Semanal" value="weekly"></vs-select-item>
            <vs-select-item text="Mensual" value="monthly"></vs-select-item>
          </vs-select>

          <!--<vs-select label="Programas" v-model="programIdSelected" class="mr-2">
            <vs-select-item
                v-for="program in programs"
                :text="program.name"
                :value="program.id"
                :key="program.id"></vs-select-item>
          </vs-select>-->

          <vs-select
              v-if="programIdSelected !== null"
              label="Zonas"
              v-model="zoneIdSelected"
              @change="addFilter('zone_id', $event)"
              class="mr-2">
            <vs-select-item text="Todas" value="0"></vs-select-item>
            <vs-select-item
                v-for="zone in programSelected.zones"
                :text="zone.name"
                :key="zone.id"
                :value="zone.id"></vs-select-item>
          </vs-select>

          <vs-select
              v-if="zoneIdSelected !== 0 && zoneSelected.regions.length > 0"
              label="Regiones"
              v-model="regionIdSelected"
              @change="addFilter('region_id', $event)">
            <vs-select-item text="Todas" value="0"></vs-select-item>
            <vs-select-item
                v-for="region in zoneSelected.regions"
                :text="region.name"
                :key="region.id"
                :value="region.id"></vs-select-item>
          </vs-select>

          <label v-if="viewFormSelected !== 'monthly'">
            Fecha de visualización
            <datepicker
                :language="lang_es"
                format="dd/MM/yyyy"
                :use-utc="false"
                @change.native="datepickerHandler()"
                v-model="visualizationDate"></datepicker>
          </label>
        </div>
      </vx-card>
    </div>

    <vs-table :data="[{}]">
      <template slot="thead">
        <vs-th class="uppercase">OPERATIVO</vs-th>
        <vs-th v-for="(range, i) in visualization.ranges" :key="`head-${i}`">
          {{ range.getRangeName(visualization.getRegex(), visualization.getFormat()) | toUppercase }}
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr>
          <vs-td colspan="14">
            <vs-divider class="bold" style="width: 100%;">PROSPECTOS</vs-divider>
          </vs-td>
        </vs-tr>
        <vs-tr v-for="(metric, m) in metrics.leads" :key="'leads-' + m">
          <vs-td>{{ metric.name }}</vs-td>
          <vs-td v-for="(range, i) in visualization.ranges" :key="`lhead-${i}`">
            <span v-if="metric.firstIndex && metric.secondIndex">
              {{ range.sum(metric.firstIndex, metric.secondIndex) }}
            </span>
          </vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td colspan="14">
            <vs-divider class="bold" style="width: 100%;">PROYECTOS</vs-divider>
          </vs-td>
        </vs-tr>
        <vs-tr v-for="(metric, m) in metrics.projects" :key="'project'+m">
          <vs-td>{{ metric.name }}</vs-td>
          <vs-td v-for="(range, i) in visualization.ranges" :key="`phead-${i}`">
            <span v-if="metric.firstIndex && metric.secondIndex">
              {{ range.sum(metric.firstIndex, metric.secondIndex) }}
            </span>
          </vs-td>
          <template slot="expand">
            <div class="w-full" v-if="metric.items">
              <vs-table :data="metric.items">
                <template slot="thead">
                  <vs-th>DESEMPEÑO</vs-th>
                  <vs-th v-for="(range, i) in visualization.ranges" :key="`head2-${i}`">
                    {{ range.visualizationName | toUppercase }}
                  </vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr v-for="(metric, m) in metric.items" :key="m">
                    <vs-td>{{ metric.name }}</vs-td>
                    <vs-td v-for="(range, i) in visualization.ranges" :key="`body2-${i}`">
                      <span v-if="metric.firstIndex && metric.secondIndex">
                        {{ range.sum(metric.firstIndex, metric.secondIndex) }}
                      </span>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from 'vuejs-datepicker'
import {es} from "vuejs-datepicker/dist/locale";

export default {
  name: "PerformanceMetric",
  data: () => ({
    performanceReport: null,
    performanceReportMap: null,
    metrics: {
      leads: [
        {name: 'Pendientes de contactar', firstIndex: 'projects', secondIndex: 'not_contact'},
        {name: 'Prospectos nuevos, sin Proveedor asignado', firstIndex: 'projects', secondIndex: 'new'},
        {name: 'Con problemas de comunicación', firstIndex: 'projects', secondIndex: 'communication_issues'},
        {name: 'Descartado por Instalador, sin viabilidad', firstIndex: 'projects', secondIndex: 'discarded'},
        {name: 'Descartados por falta de interés del cliente', firstIndex: 'projects', secondIndex: 'without_interest'},
        {name: 'Interesados en recibir propuesta', firstIndex: 'projects', secondIndex: 'interest'},
        {name: 'Con propuesta realizada', firstIndex: 'projects', secondIndex: 'with_proposal'},
        {name: '-> Menor a 30 días', firstIndex: 'projects', secondIndex: 'less_to_30'},
        {name: '-> Mayor a 30 días', firstIndex: 'projects', secondIndex: 'greater_to_30'},
        {name: 'Entregados sin financiamiento', firstIndex: 'projects', secondIndex: 'without_finance'},
      ],
      projects: [
        {name: 'Solicitudes ingresadas', firstIndex: 'requests', secondIndex: 'total'},
        {name: 'Solicitudes aprobadas', firstIndex: 'requests', secondIndex: 'approved'},
        {name: 'Proyectos en proceso', firstIndex: 'projects', secondIndex: 'in_process'},
        {name: '-> Proyectos publicados', firstIndex: 'projects', secondIndex: 'published'},
        {name: '-> Proyectos fondeados', firstIndex: 'projects', secondIndex: 'funded'},
        {name: 'Proyectos cancelados', firstIndex: 'projects', secondIndex: 'cancelled'},
        {name: '-> Solicitudes rechazadas', firstIndex: 'requests', secondIndex: 'rejected'},
        {name: '-> Proyectos archivados', firstIndex: 'projects', secondIndex: 'archived'},
        {name: '-> Proyectos reembolsados', firstIndex: 'projects', secondIndex: 'refunded'},
        {name: 'Proyectos entregados', firstIndex: 'projects', secondIndex: 'delivered'}
      ]
    },
    programIdSelected: 1,
    zoneIdSelected: 0,
    regionIdSelected: 0,
    viewFormSelected: 'monthly',
    filters: {},
    programs: [],
    statDate: '',
    visualizationDate: '',
    visualization: null,
    lang_es: es,
  }),
  components: {Datepicker},
  computed: {
    programSelected() {
      let program = this.programs.filter(p => p.id === this.programIdSelected)
      return program[0] ?? null;
    },
    zoneSelected() {
      let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
      return zone[0] ?? {regions: []};
    }
  },
  filters: {
    toUppercase(val) {
      return val.toUpperCase()
    }
  },
  watch: {
    async visualizationDate() {
      await this.datepickerHandler()
    }
  },
  async mounted() {
    const {data} = await axios.get(`/api/pgm/me/programs`)
    this.programs = data;

    this.visualizationDate = moment()
        .subtract(1, 'day')
        .format();

    await this.main(this.visualizationDate);
  },
  methods: {
    async datepickerHandler() {
      await this.main(this.visualizationDate);
    },
    async main(visualizationDate) {
      this.statDate = moment(visualizationDate)
          .format("yyyy-MM-DD");

      this.filters['program_id'] = this.programIdSelected.toString();
      await this.getAndSetData()
    },
    async changeVisualization() {
      await this.getAndSetData()
    },
    async getAndSetData() {
      let dateRanges = this.getDateRange(this.statDate);

      this.performanceReport = await this.getStatData(dateRanges);
      this.getVisualizationClass(dateRanges.endDate);
      this.visualization.init();
    },
    getVisualizationClass(startDate) {
      if (this.viewFormSelected === 'daily') {
        this.visualization = new DailyVisualization(this.performanceReport, startDate)
      } else if (this.viewFormSelected === 'weekly') {
        this.visualization = new WeeklyVisualization(this.performanceReport, startDate);
      } else {
        this.visualization = new MonthlyVisualization(this.performanceReport, startDate);
      }
    },
    async getStatData(dateRanges) {
      let metricId = '85f4bbad-9793-444a-8c98-588d4d10f4e4';
      this.$vs.loading();

      try {
        const {data} = await axios.post(`/api/analytics/pgm/performance/${metricId}`, {
          "start_date": dateRanges.startDate,
          "end_date": dateRanges.endDate,
          'filters': this.filters
        })

        return data;
      } finally {
        this.$vs.loading.close();
      }
    },
    getDateRange(reportDate) {
      if (this.viewFormSelected === 'daily') {
        let startDate = moment(reportDate).subtract(7, 'days').format("yyyy-MM-DD")
        let endDate = moment(reportDate).format("yyyy-MM-DD")

        return {startDate, endDate};
      } else if (this.viewFormSelected === 'weekly') {
        let startDate = moment(reportDate)
            .subtract(1, 'month')
            .format("yyyy-MM-DD");

        let endDate = moment(reportDate)
            .format("yyyy-MM-DD");

        return {startDate, endDate};
      } else if (this.viewFormSelected === 'monthly') {
        let startDate = moment(reportDate)
            .subtract(12, 'months')
            .startOf('year')
            .format("yyyy-MM-DD")

        let endDate = moment(reportDate)
            .format("yyyy-MM-DD")

        return {startDate, endDate};
      }
    },
    async addFilter(filterName, ev) {
      if (ev.toString() === "0") {
        delete this.filters[filterName];
      } else {
        this.filters[filterName] = ev.toString();
      }
      await this.getAndSetData()
    }
  }
}

class Range {
  constructor(startDate, endDate) {
    this.stats = [];
    this.startDate = startDate;
    this.endDate = endDate;
  }

  addStat(stat) {
    this.stats.push(stat);
  }

  getRangeName(regex, format) {
    let startDate = moment(this.startDate).locale("es").format(format)
    let endDate = moment(this.endDate).locale("es").format(format)

    let rangeName = regex.replace("%startDate%", startDate)
    rangeName = rangeName.replace("%endDate%", endDate)
    return rangeName;
  }

  sum(prop, index) {
    let sum = 0;
    for(let stat of this.stats) {
      sum += stat[prop][index];
    }
    return sum;
  }

  /**
   * @return boolean
   */
  hasStats() {
    return this.stats.length > 0;
  }
}

class Visualization {
  constructor(data) {
    this.data = data;
    this.ranges = [];
    this.rangesIndex = {};
  }

  /**
   * @param {string} startDate
   * @param {string} endDate
   */
  addRange(startDate, endDate) {
    let rangeIndexName = this.getIndexName(startDate, endDate);
    if (typeof this.rangesIndex[rangeIndexName] === "undefined") {
      this.rangesIndex[rangeIndexName] = new Range(startDate, endDate);
      this.ranges.push(this.rangesIndex[rangeIndexName])
    }
  }

  getByRange(startDate, endDate) {
    let rangeIndexName = this.getIndexName(startDate, endDate);
    if (this.rangesIndex[rangeIndexName]) {
      return this.rangesIndex[rangeIndexName];
    }
    return null;
  }

  getIndexName(startDate, endDate) {
    return `${startDate}|${endDate}`;
  }

  /**
   * @param {object} stat
   * @param {string} reportDate
   * @return {void}
   */
  addStat(stat, reportDate) {
    let range = this.ranges.filter((range) => {
      return moment(reportDate)
          .isBetween(range.endDate, range.startDate) || range.startDate === reportDate;
    })

    if (range.length > 0) {
      range[0].addStat(stat)
    }
  }

  setDataIndexed() {
    for (let stat of this.data) {
      this.addStat(stat, stat.report_date)
    }
  }
}

class DailyVisualization extends Visualization {
  constructor(data, startDate) {
    super(data);
    this.startDate = startDate;
    this.numberDays = 7;
  }

  init() {
    this.getDateRanges()
    this.setDataIndexed()
  }

  getRegex() {
    return "%startDate%";
  }

  getFormat() {
    return  "dddd D [de] MMM";
  }

  getDateRanges() {
    let momentStartDate = moment(this.startDate);

    for (let i = 0; i < this.numberDays; i++) {
      let startDate = momentStartDate
              .clone()
              .subtract(i, 'day')
              .format('YYYY-MM-DD');

      this.addRange(startDate, startDate)
    }
  }
}

class WeeklyVisualization extends Visualization {
  constructor(data, startDate) {
    super();
    this.data = data;
    this.startDate = startDate;
    this.weeksNumber = 4;
  }

  init() {
    this.getDateRanges()
    this.setDataIndexed()
  }

  getRegex() {
    return "%startDate% al %endDate%";
  }

  getFormat() {
    return  "YYYY-MM-DD";
  }

  getDateRanges() {
    let momentStartDate = moment(this.startDate);

    for (let i = 0; i < this.weeksNumber; i++) {
      let subStartDay = i;
      let subEndDay = i + 1;

      if (i === 0) {
        let startDate = momentStartDate
            .clone()
            .format('YYYY-MM-DD')

        let endDate = momentStartDate
            .clone()
            .subtract(subEndDay, 'week')
            .format('YYYY-MM-DD');

        this.addRange(startDate, endDate)

      } else {
        let _startDate = momentStartDate
            .clone()
            .subtract(subStartDay, 'week')
            .subtract(i, 'day');

        let startDate = _startDate.format('YYYY-MM-DD');
        let endDate = momentStartDate
            .clone()
            .subtract(subEndDay, 'week')
            .subtract(i, 'day')
            .format('YYYY-MM-DD');

        this.addRange(startDate, endDate)
      }
    }
  }
}

class MonthlyVisualization extends Visualization {
  constructor(data, startDate) {
    super(data)
    this.startDate = startDate;
    this.months = 12;
  }

  getDateRanges() {
    let momentStartDate = moment(this.startDate);

    for (let i = 0; i < this.months; i++) {
      let startDate = momentStartDate
          .clone()
          .subtract(i, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');

      let endDate = momentStartDate
          .clone()
          .subtract(i, 'month')
          .endOf('month')
          .format('YYYY-MM-DD')

      this.addRange(endDate, startDate)
    }
  }
  init() {
    this.getDateRanges()
    this.setDataIndexed()
  }


  getRegex() {
    return "%startDate%";
  }

  getFormat() {
    return  "MMMM-YYYY";
  }

}
</script>

<style scoped>
.tr-expand {
  width: 100%;
}

.tr-expand td {
  width: 100%;
}

.tr-expand td div {
  width: 100%;
}
</style>
